import React from "react";
import { currencyFormatterOptions } from "../helpers/constants";
import { useGetFundRaisedByCampaign } from "../helpers/hooks";
import styles from "./fundsCollectionStatus.module.scss";

const FundsCollectionStatusSection = props => {
  const slug = props.campaign.slug;
  const goal = props.campaign.goal;
  const goalCurrency = props.campaign.goalCurrency;
  const { data: { fundRaised } = {} } = useGetFundRaisedByCampaign(slug);
  const currencyFormatter = new Intl.NumberFormat("en-IN", {
    currency: goalCurrency,
    ...currencyFormatterOptions,
  });
  const progressPercentage =
    fundRaised === undefined ? 0 : (fundRaised / goal) * 100;
  return (
    <section
      className={`${styles.fundsCollectionSection} w-100 d-flex bgGradientBlurToGreen`}
    >
      <div className={`${styles.goal} text-left`}>
        <h2 className="text-white font-weight-normal text-uppercase">RAISED</h2>
        <h3 className="text-54EDFF font-weight-bold">
          {" "}
          {currencyFormatter.format(fundRaised)}
        </h3>
      </div>
      <div
        className={`${styles.raised} position-relative text-right text-md-left pl-md-4`}
      >
        <span
          className={`${styles.separator} d-block position-absolute`}
        ></span>
        <h2 className="text-white font-weight-normal text-uppercase">GOAL</h2>
        <h3 className="text-54EDFF font-weight-bold">
          {goal === undefined ? <>&mdash;</> : currencyFormatter.format(goal)}
        </h3>
      </div>
      <div
        className={`${styles.percentage} mt-5 mt-lg-0 position-relative d-flex flex-column justify-content-center pl-4 pr-4 pr-md-0`}
      >
        <span
          className={`${styles.separator} d-none d-lg-block position-absolute`}
        ></span>
        <progress
          className={`${styles.percentageBar} w-100`}
          value={progressPercentage}
          max={100}
        ></progress>
        <div
          style={{ width: progressPercentage + "%" }}
          className={`position-relative ${styles.floatingPercentageContainer}`}
        >
          <div
            className={`${styles.floatingPercentage} position-absolute text-white font-16 text-center px-3 py-2 font-weight-bolder`}
          >
            {progressPercentage.toFixed(1)}%
          </div>
        </div>
      </div>
    </section>
  );
};

export default FundsCollectionStatusSection;
