import React, { useState, useContext } from "react";
import sendMail from "../helpers/emailSender";
import infoIcon from "../assets/info-icn.svg";
import { StateContext } from "../store/context";
import { hideSpinner, showSpinner, showToast } from "../store/actions";
import styles from "./createFundRaiser.module.scss";
import MobileNumberInput from "./mobileNumberInput";
export default function SupportFundraiserModal({
  contentfulId,
  campaignSlug,
  campaignTitle,
  campaignGoal,
  campaignGoalCurrency,
}) {
  const [showSupportForm, setShowSupportForm] = useState(true);
  const [userJson, setUserJson] = useState(null);
  const [sendOTP, setSendOTP] = useState(false);
  const [resendOTP, setResendOTP] = useState(false);
  const { dispatch } = useContext(StateContext);
  const PhoneNumberUpdated = e => {
    if (sendOTP === true) {
      setSendOTP(false);
    }
  };

  async function generateReferralCode(text) {
    const msgUint8 = new TextEncoder().encode(text);
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map(b => b.toString(16).padStart(2, "0"))
      .join("");
    return hashHex;
  }

  async function getJSONFormData(event) {
    let json = {};
    new FormData(event.target).forEach((value, key) => {
      if (key === "mobileNumber") value = value.replace(/[\s-()]/g, "");
      json[key] = value;
    });
    return json;
  }

  async function getUserFormDataAndGetOTP(event, resend) {
    let json = {};
    if (!resend) {
      json = await getJSONFormData(event);
      setUserJson(json);
    }

    const payload = {
      moduleName: "campaignAggregator",
      templateName: "sendOTP",
      mobileNumber: !resend ? json.mobileNumber : userJson.mobileNumber,
    };
    getOrVerifyOTP("getOTP", JSON.stringify(payload), "sendSMS");
  }

  async function getOTPFormDataAndVerifyOTP(event) {
    const json = await getJSONFormData(event);
    const payload = {
      moduleName: "campaignAggregator",
      otp: json.otp,
      mobileNumber: userJson.mobileNumber,
    };
    getOrVerifyOTP("verifyOTP", JSON.stringify(payload), "verifyCode");
  }

  const resendOTPToUser = async event => {
    setResendOTP(true);
    event.preventDefault();
    getUserFormDataAndGetOTP(event, true);
  };

  async function createChildCampaign() {
    const childCampaignSlug = await generateReferralCode(
      campaignSlug + userJson.mobileNumber
    );
    const payload = {
      name: `${userJson.firstName} ${userJson.lastName}`,
      goal: parseInt(userJson.goal),
      email: userJson.email,
      mobileNumber: userJson.mobileNumber,
      parentCampaignId: contentfulId,
      parentCampaignTitle: campaignTitle,
      parentCampaignSlug: campaignSlug,
      appeal: userJson.appeal,
      childCampaignSlug: childCampaignSlug,
      tag: `${process.env.GATSBY_ENV === "dev-" ? "envDev" : "envProd"}`,
    };

    try {
      showSpinner(dispatch, "Creating child campaign...");
      let requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
        redirect: "follow",
      };
      const resdata = await fetch(
        `${process.env.GATSBY_API_BASE_URL}/campaignAggregator/createChildCampaign`,
        requestOptions
      ).then(resp => resp.json());
      if (resdata.message === "Child Campaign Created Successfully") {
        sendMail({
          templateName: "childCampaignSubmit",
          sendTo: userJson.email,
          subject: `Supporting Campaign Created for ${campaignTitle}`,
          moduleName: "campaign-aggregator",
          templateData: {
            name: `${userJson.firstName} ${userJson.lastName}`,
            campaignName: campaignTitle,
          },
        });
        setShowSupportForm(false);
      } else {
        showToast(dispatch, resdata.message, "danger");
      }
      hideSpinner(dispatch);
    } catch (err) {
      hideSpinner(dispatch);
      console.error(err, JSON.stringify(err));
      showToast(
        dispatch,
        "Oops, something went wrong. Please try in some time.",
        "danger"
      );
    }
  }

  async function getOrVerifyOTP(type, raw, api) {
    try {
      if (type === "getOTP") {
        showSpinner(dispatch, "Requesting OTP...");
      } else {
        showSpinner(dispatch, "Verifying OTP...");
      }
      let requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: raw,
        redirect: "follow",
      };
      const resdata = await fetch(
        `https://alerts.mettasocial.com/${api}`,
        requestOptions
      ).then(resp => resp.json());

      if (!resendOTP) setResendOTP(false);

      if (type === "getOTP") {
        showToast(dispatch, resdata.message, "success");
        setSendOTP(true);
      } else if (type === "verifyOTP") {
        if (resdata.message === "OTP Verified") {
          createChildCampaign();
        } else {
          showToast(dispatch, resdata.message, "danger");
        }
      }
      hideSpinner(dispatch);
    } catch (err) {
      hideSpinner(dispatch);
      console.error(err, JSON.stringify(err));
      showToast(
        dispatch,
        "Oops, something went wrong. Please try in some time.",
        "danger"
      );
      if (!resendOTP) setResendOTP(false);
    }
  }

  const checkValidation = async (event, type) => {
    event.persist();
    event.preventDefault();
    event.stopPropagation();
    var forms = document.getElementsByClassName(
      type === "getOTP" ? "needs-validation-user" : "needs-validation-otp"
    );
    Array.prototype.filter.call(forms, function (form) {
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
        for (let index = 0; index < form.elements.length; index++) {
          if (form.elements[index].validity.valid === false) {
            const element = document.getElementsByName(
              form.elements[index].name
            )[0];
            element.select();
            element.focus();
            element.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
            break;
          }
        }
      } else {
        if (type === "getOTP") getUserFormDataAndGetOTP(event, false);
        else getOTPFormDataAndVerifyOTP(event);
      }
    });
  };

  return (
    <div className="w-100">
      <div
        className={`${styles.labelButtonContainer} d-flex align-items-center`}
      >
        <span className={`${styles.headerLabel}`}>
          You are creating a supporting fundraiser
        </span>
      </div>
      <div className={!showSupportForm ? "d-none" : ""}>
        <div className="mb-4">
          <h5
            className={`mx-0 mb-2 ${styles.popUpTitle} font-24 font-weight-bolder text-435061 text-center text-md-left`}
          >
            <span>{campaignTitle}</span>
          </h5>
        </div>
        <form
          className="needs-validation-user"
          onSubmit={e => {
            checkValidation(e, "getOTP");
          }}
          noValidate
        >
          <div className="row">
            <div
              className={`form-group formGroup mx-0 col-6 col-md-6 mb-4 mb-md-4 pr-2 pr-md-3 ${styles.formGroup}`}
            >
              <input
                className="form-control"
                type="text"
                id="firstName"
                name="firstName"
                required
                pattern="^[a-zA-Z ]*$"
                title="Only alphabets are allowed"
              ></input>
              <label htmlFor="firstName">First Name</label>
              <div className="invalid-feedback">
                Please enter a valid first name.
              </div>
            </div>

            <div
              className={`form-group formGroup mx-0 col-6 col-md-6 mb-4 mb-md-4 pl-2 pl-md-3 ${styles.formGroup}`}
            >
              <input
                className="form-control"
                type="text"
                id="lastName"
                name="lastName"
                required
                pattern="^[a-zA-Z ]*$"
                title="Only alphabets are allowed"
              ></input>
              <label htmlFor="lastName">Last Name</label>
              <div className="invalid-feedback">
                Please enter a valid last name.
              </div>
            </div>
          </div>
          <div className="row pb-1">
            <div
              className={`form-group formGroup mx-0 row col-12 col-md-6 mb-4 mb-md-0 ${styles.formGroup}`}
            >
              <input
                className="form-control "
                type="email"
                id="email"
                name="email"
                placeholder="Email Address"
                required
              ></input>
              <label htmlFor="email">Email Address</label>
              <div className="invalid-feedback">
                Please enter a valid email address.
              </div>
            </div>

            <div
              className={`form-group formGroup mx-0 row col-12 col-md-6 mb-4 mb-md-0 ${styles.formGroup}`}
            >
              <MobileNumberInput
                name="mobileNumber"
                onChangeFunc={true}
                onchange={e => {
                  PhoneNumberUpdated(e);
                }}
              />
              <label htmlFor="phone">
                Mobile Number<span className="ml-1">*</span>
              </label>
              <div className="invalid-feedback">
                Please enter a valid mobile number.
              </div>
            </div>
          </div>
          <hr className="my-4" />
          <div className="row pt-1">
            <div
              className={`form-group formGroup mx-0 row col-12 col-md-6 mb-4 mb-md-4 ${styles.formGroup}`}
            >
              <input
                className="form-control borderDashed bgWhiteImp text-637285-imp"
                type="text"
                value={`${campaignGoalCurrency} ${campaignGoal}`}
                disabled
              ></input>
              <label htmlFor="Read only Parent Campaign Goal amount">
                Parent Campaign Goal Amount
              </label>
            </div>
            <div
              className={`form-group formGroup mx-0 row col-12 col-md-6 mb-4 mb-md-4 ${styles.formGroup}`}
            >
              <input
                className="form-control "
                type="number"
                id="goal"
                name="goal"
                placeholder="Goal Amount"
                max={campaignGoal}
                min={1}
                required
              ></input>
              <label htmlFor="Goal amount">Goal Amount</label>
              <small className="text-637285 font-12 d-flex align-items-center">
                <img
                  src={infoIcon}
                  className={`${styles.infoIcon} mr-1`}
                  alt="Info icon"
                />{" "}
                Should be less than the parent goal
              </small>
              <div className="invalid-feedback">
                Please enter a valid amount.
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className={`form-group formGroup mx-0 row col-12 mb-4 mb-md-4 ${styles.formGroup}`}
            >
              <textarea
                className="form-control "
                id="appeal"
                name="appeal"
                placeholder="Make an appeal"
                rows={2}
                required
              ></textarea>
              <label htmlFor="appeal">Make an Appeal</label>
              <div className="invalid-feedback">
                Please enter a valid appeal
              </div>
            </div>
          </div>
          <div className={`row m-0 ${sendOTP ? "d-none" : ""}`}>
            <button
              className="btn button-8BC540 btn-custom w-100 text-uppercase"
              type="Submit"
            >
              Start My Fundraiser
            </button>
          </div>
        </form>
        <form
          className={`needs-validation-otp mt-3 ${!sendOTP ? "d-none" : ""}`}
          onSubmit={e => {
            checkValidation(e, "verifyOTP");
          }}
          noValidate
        >
          <div className="row pt-1">
            <div
              className={`form-group formGroup mx-0 row col-12 mb-4 mb-md-4 ${styles.formGroup}`}
            >
              <input
                className="form-control "
                type="text"
                id="otp"
                name="otp"
                maxLength={6}
                minLength={6}
                placeholder="Enter OTP sent to your Phone"
                required
              ></input>
              <label htmlFor="Goal amount">Enter OTP Sent To Your Phone</label>
              <div className="invalid-feedback">
                Please enter a valid amount.
              </div>
            </div>
          </div>
          <div className="text-right">
            <small
              className={`d-inline-block text-right mb-2 text-underline ${
                !resendOTP
                  ? "text-warning cursor-pointer"
                  : "text-secondary cursor-not-allowed"
              }`}
              onClick={resendOTPToUser}
            >
              Resend OTP {resendOTP}
            </small>
          </div>
          <div className="row m-0">
            <button
              className="btn button-8BC540 btn-custom w-100 text-uppercase"
              type="Submit"
            >
              Verify OTP
            </button>
          </div>
        </form>
      </div>
      <div className={showSupportForm ? "d-none" : ""}>
        <div className="mb-4">
          <h5
            className={`mx-0 mb-2 ${styles.popUpTitle} font-24 font-weight-bolder text-435061 text-center text-md-left`}
          >
            Your Supporting Fundraiser for{" "}
            <span className="text-8BC540">&quot;{campaignTitle}&quot;</span> is
            under review
          </h5>
          <p className={`text-637285 font-18 text-center text-md-left`}>
            Our team is reviewing your Supporting Fundraiser. You will receive
            an email regarding its status within 2 working days.
          </p>
        </div>
      </div>
    </div>
  );
}
