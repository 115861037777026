import React, { useContext, useState } from "react";
import { showModal } from "../store/actions";
import { StateContext } from "../store/context";
import { useGetCampaignDonationData } from "../helpers/hooks";
import { DonateNowForm } from "./donateNowForm";
import styles from "./donationInfo.module.scss";
import DonorDetails from "./donorDetails";
import HelpModal from "./helpModal";
import Picture from "./picture";
import NoDonorsYet from "./noDonorsYet";

function MostGenerousDonors(props) {
  const slug = props.slug;
  let generousData = useGetCampaignDonationData("amount", slug);
  if (generousData && !Array.isArray(generousData)) {
    generousData = generousData.campaignPayments;
    props.setTopDonorsListLength(generousData.length);
  }
  if (generousData && generousData.length > 0) {
    return generousData.map(x => <DonorDetails donor={x} key={x.createdAt} />);
  }
  return <NoDonorsYet colorClass={"text-white"} text={"No Donors Yet"} />;
}

function MostRecentDonors(props) {
  const slug = props.slug;
  let recentData = useGetCampaignDonationData("createdAt", slug);
  if (recentData && !Array.isArray(recentData)) {
    recentData = recentData.campaignPayments;
    props.setRecentDonorsListLength(recentData.length);
  }
  if (recentData && recentData.length > 0) {
    return recentData.map(x => <DonorDetails donor={x} key={x.createdAt} />);
  }
  return <NoDonorsYet colorClass={"text-white"} text={"No Donors Yet"} />;
}

const DonationInfoSection = ({ campaign, banner, className, type }) => {
  const [donationListScrollable, setDonationListScrollable] = useState(false);

  const enableScroller = () => {
    setDonationListScrollable(true);
  };

  const slug = campaign.slug;
  const { dispatch } = useContext(StateContext);
  const [topDonorsListLength, setTopDonorsListLength] = useState(0);
  const [recentDonorsListLength, setRecentDonorsListLength] = useState(0);

  return (
    <section
      className={`col-12 ${className} ${
        type && type === "subSection" ? " px-0 px-md-3" : ""
      }`}
    >
      <div className={`row px-0 ${styles.mapDonorRow}`}>
        <div
          className={`col-12 col-lg-12 ${
            type && type === "subSection" ? " px-0 px-md-3" : ""
          }`}
        >
          <div
            className={`${styles.donorListSwitch} d-flex align-items-center`}
          >
            <ul
              className={`col-12 row px-0 nav nav-tabs h4 text-uppercase donor-list-nav-ul m-0 ${styles.donorListNavTabContainer} w-100 h-100`}
              role="tablist"
            >
              <li
                className="nav-item col-6 px-0 text-center"
                role="presentation"
              >
                <a
                  className={`lead nav-link active text-uppercase ${styles.donorListbutton} w-100 h-100 p-0 m-0 font-16 font-weight-bolder cursor-pointer text-center`}
                  data-toggle="tab"
                  href="#most-generous"
                  id="most-generous-tab"
                  role="tab"
                  aria-controls="most-generous"
                  aria-selected="true"
                >
                  Top Donors
                </a>
              </li>
              <li
                className="nav-item col-6 px-0 text-center"
                role="presentation"
              >
                <a
                  className={`lead nav-link text-uppercase ${styles.donorListbutton} w-100 h-100 p-0 m-0 font-16 font-weight-bolder cursor-pointer text-center`}
                  data-toggle="tab"
                  href="#most-recent"
                  id="most-recent-tab"
                  role="tab"
                  aria-controls="most-recent"
                  aria-selected="false"
                >
                  Recent Donors
                </a>
              </li>
            </ul>
          </div>
          <div
            className={`${styles.donorListContainer} h-100 position-relative`}
          >
            <Picture
              image={banner[0].image}
              className={`abs-full img-default`}
            />
            <div
              className={`${styles.bgImgOverlay} abs-top-left w-100 h-100`}
            ></div>
            <div
              className={`col-12 px-0 tab-content border-darken-1 ${
                donationListScrollable === true ? styles.listScrollable : ""
              }`}
              id="myTabContent"
            >
              <div
                className={`col-12 px-0 table-responsive tab-pane fade active show ${styles.tabPane} ${styles.donorList}`}
                id="most-generous"
                role="tabpanel"
                aria-labelledby="most-generous-tab"
              >
                <MostGenerousDonors
                  slug={slug}
                  setTopDonorsListLength={setTopDonorsListLength}
                />
              </div>
              <div
                className={`col-12 px-0 table-responsive tab-pane fade ${styles.tabPane}  ${styles.donorList}`}
                id="most-recent"
                role="tabpanel"
                aria-labelledby="most-recent-tab"
              >
                <MostRecentDonors
                  slug={slug}
                  setRecentDonorsListLength={setRecentDonorsListLength}
                />
              </div>
            </div>
            {!donationListScrollable &&
              (topDonorsListLength > 5 || recentDonorsListLength > 5) && (
                <div
                  className={`${styles.viewMoreContainer} text-right position-relative`}
                >
                  <button
                    className={`btn button-8BC540 ${styles.viewMoreButton} font-16 fw-500 letter-spacing-019 width-fit-content text-uppercase`}
                    onClick={enableScroller}
                    id="view-more"
                  >
                    View more
                  </button>
                </div>
              )}
          </div>
          <div
            className={`${styles.donateContainer} d-flex align-items-center`}
          >
            <span
              className={` ${styles.donateLabel} d-none d-lg-flex align-items-center font-20 mr-4`}
            >
              Come, let’s join hands
            </span>
            <button
              className={`btn button-8BC540 ${styles.captionButton} ${styles.footerbtn} font-16 fw-500 letter-spacing-019 width-fit-content text-uppercase`}
              id="donor-list-donate-now"
              type="button"
              onClick={() =>
                showModal(
                  dispatch,
                  () => <DonateNowForm campaign={campaign} />,
                  () =>
                    showModal(dispatch, () => <HelpModal campaign={campaign} />)
                )
              }
            >
              Donate Now
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DonationInfoSection;
