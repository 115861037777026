import React, { useContext, useState } from "react";
import { useLeaderboardList } from "../helpers/hooks";
import { currencyFormatterOptions } from "../helpers/constants";
import nameInitials from "../helpers/nameInitials";
import { showModal } from "../store/actions";
import { StateContext } from "../store/context";
import { ShareNow } from "./shareNow";
import styles from "./leaderboardSection.module.scss";
import NoDonorsYet from "./noDonorsYet";

function LeaderboardList(props) {
  const slug = props.slug;
  let leaderboardData = useLeaderboardList(slug);
  let leaderboard = [];
  for (const key in leaderboardData.data) {
    if (leaderboardData.data[key]) leaderboard.push(leaderboardData.data[key]);
  }
  props.setDonorsListLength(leaderboard.length);
  if (leaderboard && leaderboard.length > 0) {
    return leaderboard.map(x => (
      <div
        className={`d-flex justify-content-between ${styles.borderedDonorRow} align-items-center pr-2`}
        key={x.sourceName}
      >
        <div className={`text-272D4E d-flex`}>
          <span
            className={`${styles.initials} text-uppercase text-center text-white`}
          >
            {nameInitials(x.reffererName)}
          </span>
          <span className="text-272D4E">
            <p className={`mb-0 font-18 font-weight-bolder`}>
              {x.reffererName}
            </p>
            <p className={`mb-0 font-14`}>{x.country}</p>
          </span>
        </div>
        <span className={`text-272D4E font-18`}>
          {new Intl.NumberFormat("en-IN", {
            currency: "INR",
            ...currencyFormatterOptions,
          }).format(x.amount)}
        </span>
      </div>
    ));
  }
  return (
    <NoDonorsYet colorClass={"text-black"} text={"No Social Shares Yet"} />
  );
}

const LeaderBoardSection = ({ campaign }) => {
  const [donationListScrollable, setDonationListScrollable] = useState(false);
  const slug = campaign.slug;
  const { dispatch } = useContext(StateContext);
  const [donorsListLength, setDonorsListLength] = useState(0);

  const enableScroller = () => {
    setDonationListScrollable(true);
  };

  const handleClick = e => {
    e.preventDefault();
    showModal(dispatch, () => <ShareNow slug={slug} title={campaign.title} />);
    document.body.style.overflow = "hidden";
  };

  return (
    <section className={`col-12 ${styles.donationSection}`}>
      <span className="pill-label pill-bg-gradient">Power of social media</span>
      <h3 className="section-title text-black">
        Most Raised from Social Sharing
      </h3>
      <div className={`row px-0 ${styles.mapDonorRow}`}>
        <div className="col-12">
          <p className="font-16 text-5B6182 text-center">
            Every social media share increases the chances of getting funds up
            to 3 times
          </p>
          <div
            className={`${styles.mostRaisedList} my-4  my-lg-5pr-lg-5 ${
              donationListScrollable === true ? styles.listScrollable : ""
            }`}
          >
            <LeaderboardList
              slug={slug}
              setDonorsListLength={setDonorsListLength}
            />
            <small
              className={`d-block ${
                donorsListLength > 0 ? "text-right" : "text-center"
              }`}
            >
              Updated every 24 hours
            </small>
          </div>
          {!donationListScrollable && donorsListLength > 5 && (
            <div
              className={`${styles.viewMoreContainer} text-right position-relative`}
            >
              <button
                className={`btn button-8BC540 ${styles.viewMoreButton} font-16 fw-500 letter-spacing-019 width-fit-content text-uppercase`}
                onClick={enableScroller}
                id="view-more"
              >
                View more
              </button>
            </div>
          )}
        </div>
        <div className="col-12 mt-4 px-lg-0">
          <p className="text-91A1B4 font-18 text-center font-italic">
            Share with the world & inspire others
          </p>
          <div className="row justify-content-center mx-0">
            <button
              className={`btn button-8BC540 btn-custom font-16 fw-500 letter-spacing-019 width-fit-content text-uppercase mr-2 mb-2`}
              onClick={e => {
                handleClick(e);
              }}
            >
              Share Now
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LeaderBoardSection;
