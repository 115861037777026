import React, { useState, useContext } from "react";
import { StateContext } from "../store/context";
import { hideSpinner, showSpinner } from "../store/actions";
import sendMail from "../helpers/emailSender";
import styles from "./shareNow.module.scss";
import MobileNumberInput from "./mobileNumberInput";

export const ShareNow = props => {
  const [showGenerateLink, setShowGenerateLink] = useState(true);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [referralLink, setReferralLink] = useState(null);
  const { dispatch } = useContext(StateContext);

  const copyTextToClipBoard = async event => {
    navigator.clipboard.writeText(referralLink);
  };

  async function generateReferralCode(email) {
    const msgUint8 = new TextEncoder().encode(email);
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map(b => b.toString(16).padStart(2, "0"))
      .join("");
    return hashHex;
  }

  function setErrorAndTimeOut(text) {
    setShowError(true);
    setErrorMessage(text);
    const errorDisplayTimeout = setTimeout(() => {
      clearTimeout(errorDisplayTimeout);
      setShowError(false);
      setErrorMessage(null);
    }, 7000);
  }

  async function sendReferralCode(json) {
    let raw = JSON.stringify(json);
    try {
      showSpinner(dispatch, "Generating referral link...");
      let requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: raw,
        redirect: "follow",
      };
      const resdata = await fetch(
        `${process.env.GATSBY_API_BASE_URL}/campaignAggregator/generateCampaignReferral`,
        requestOptions
      ).then(resp => resp.json());
      if (
        resdata === "Data saved successfully" ||
        resdata === "Referral already exists"
      ) {
        setShowGenerateLink(false);
      }
      hideSpinner(dispatch);
    } catch (err) {
      hideSpinner(dispatch);
      console.error(err, JSON.stringify(err));
      setErrorAndTimeOut("Link Generation failed. Please try after some time.");
    }
  }

  async function showCopyLink(event) {
    let json = {};

    new FormData(event.target).forEach((value, key) => {
      json[key] = value;
    });

    const refferalCode = await generateReferralCode(json.email);
    json["referralCode"] = refferalCode.slice(0, 7);
    setReferralLink(
      `${process.env.GATSBY_PUBLIC_URL}/campaign/${
        props.slug
      }/?utm_source=${refferalCode.slice(0, 7)}`
    );
    sendMail({
      templateName: "referralLinkGenerated",
      sendTo: json.email,
      subject: `Congratulations! Your referral link is generated`,
      moduleName: "campaign-aggregator",
      templateData: {
        name: `${json.firstName} ${json.lastName}`,
        campaignName: props.title,
        link: `${process.env.GATSBY_PUBLIC_URL}/campaign/${
          props.slug
        }/?utm_source=${refferalCode.slice(0, 7)}`,
      },
    });
    sendReferralCode(json);
  }

  const checkValidation = async event => {
    event.persist();
    event.preventDefault();
    event.stopPropagation();
    var forms = document.getElementsByClassName("needs-validation");
    Array.prototype.filter.call(forms, function (form) {
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
        for (let index = 0; index < form.elements.length; index++) {
          if (form.elements[index].validity.valid === false) {
            const element = document.getElementsByName(
              form.elements[index].name
            )[0];
            element.select();
            element.focus();
            element.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
            break;
          }
        }
      } else {
        showCopyLink(event);
      }
    });
  };

  return (
    <div>
      <div
        className={`${styles.labelButtonContainer} d-flex justify-content-between align-items-center`}
      >
        <span className={`${styles.headerLabel} text-center`}>great work!</span>
      </div>
      <div className={!showGenerateLink ? "d-none" : ""}>
        <div className="mb-4">
          <h5
            className={`mx-0 mb-2 mb-md-0 ${styles.popUpTitle} font-24 font-weight-bolder text-435061 text-center text-md-left`}
          >
            Generate your unique referral link
          </h5>
          <p className={`text-637285 font-18 text-center text-md-left`}>
            Help us with below details
          </p>
        </div>
        <form
          className="needs-validation"
          onSubmit={checkValidation}
          noValidate
        >
          <div className="row">
            <div
              className={`form-group formGroup mx-0 col-6 col-md-6 mb-4 mb-md-4 pr-2 pr-md-3 ${styles.formGroup}`}
            >
              <input
                className="form-control"
                type="text"
                id="firstName"
                name="firstName"
                required
                pattern="^[a-zA-Z ]*$"
                title="Only alphabets are allowed"
              ></input>
              <label htmlFor="firstName">First Name</label>
              <div className="invalid-feedback">
                Please enter a valid first name.
              </div>
            </div>

            <div
              className={`form-group formGroup mx-0 col-6 col-md-6 mb-4 mb-md-4 pl-2 pl-md-3 ${styles.formGroup}`}
            >
              <input
                className="form-control"
                type="text"
                id="lastName"
                name="lastName"
                required
                pattern="^[a-zA-Z ]*$"
                title="Only alphabets are allowed"
              ></input>
              <label htmlFor="lastName">Last Name</label>
              <div className="invalid-feedback">
                Please enter a valid last name.
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div
              className={`form-group formGroup mx-0 row col-12 col-md-6 mb-4 mb-md-4 ${styles.formGroup}`}
            >
              <input
                className="form-control "
                type="email"
                id="email"
                name="email"
                placeholder="Email Address"
                required
              ></input>
              <label htmlFor="email">Email Address</label>
              <div className="invalid-feedback">
                Please enter a valid email address.
              </div>
            </div>

            <div
              className={`form-group formGroup standard-field mx-0 row col-12 col-md-6 mb-4 mb-md-4 ${styles.formGroup}`}
            >
              <MobileNumberInput name="phone" onChangeFunc={false} />
              <label htmlFor="phone">
                Mobile Number<span className="ml-1">*</span>
              </label>
              <div className="invalid-feedback">
                Please enter a valid mobile number.
              </div>
            </div>
          </div>
          <div className="row m-0">
            <button
              className="btn button-8BC540 btn-custom w-100 text-uppercase"
              type="Submit"
            >
              Generate your own unique URL
            </button>
          </div>
          <p
            className={`text-FF0000 font-14 mt-2 mb-0 text-center ${
              !showError ? "d-none" : ""
            }`}
          >
            {errorMessage}
          </p>
        </form>
      </div>
      <div className={showGenerateLink ? "d-none" : ""}>
        <div className="mb-4">
          <h5
            className={`mx-0 mb-2 mb-md-0 ${styles.popUpTitle} font-24 font-weight-bolder text-435061 text-center text-md-left`}
          >
            Congratulations! Your referral link is generated
          </h5>
          <p className={`text-637285 font-18 text-center text-md-left`}>
            It’s time to share the link with your friends
          </p>
        </div>
        <div>
          <div
            className={`${styles.generatedLinkContainer} d-flex flex-column flex-md-row align-items-center justify-content-between mb-5`}
          >
            <div
              className={`text-637285 my-2 my-md-0 ml-0 ml-md-2 ${styles.referralLink} text-ellipsis`}
            >
              {referralLink}
            </div>
            <button
              type="button"
              className={`btn button-8BC540 btn-custom text-uppercase ${styles.copyLinkBtn}`}
              onClick={copyTextToClipBoard}
            >
              copy link
            </button>
          </div>
          <p
            className={`text-91A1B4 font-20 font-italic text-center mb-0 ${styles.shareText}`}
          >
            Share with the world & inspire others
          </p>
          <div className="row justify-content-center align-items-center flex-wrap">
            <button
              className={`btn btn-custom btn-bg-whatsapp width-fit-content mt-3 position-relative col-3 mx-2 mx-md-1 col-md-2`}
            >
              <a
                href={`https://wa.me/?text=${referralLink}`}
                data-action="share/whatsapp/share"
                target="_blank"
                rel="noreferrer"
                className="abs-full opacityNone overflowHidden"
              >{`https://wa.me/?text=${referralLink}`}</a>
              <img
                src="/icons/whatsapp.svg"
                alt="Whatsapp"
                className={styles.socialShareBtns}
              />
            </button>
            <button
              className={`btn btn-custom btn-bg-fb width-fit-content mt-3 position-relative col-3 mx-2 mx-md-1 col-md-2`}
            >
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${referralLink}`}
                target="_blank"
                rel="noreferrer"
                className="abs-full opacityNone overflowHidden"
              >{`https://www.facebook.com/sharer/sharer.php?u=${referralLink}`}</a>
              <img
                src="/icons/facebook.svg"
                alt="Facebook"
                className={styles.socialShareBtns}
              />
            </button>
            <button
              className={`btn btn-custom btn-bg-twitter width-fit-content mt-3 position-relative col-3 mx-2 mx-md-1 col-md-2`}
            >
              <a
                href={`https://twitter.com/intent/tweet?url==${referralLink}`}
                data-action="share/whatsapp/share"
                target="_blank"
                rel="noreferrer"
                className="abs-full opacityNone overflowHidden"
              >{`https://twitter.com/intent/tweet?url==${referralLink}`}</a>
              <img
                src="/icons/twitter.svg"
                alt="Twitter"
                className={styles.socialShareBtns}
              />
            </button>
            <button
              className={`btn btn-custom btn-bg-pinterest width-fit-content mt-3 position-relative col-3 mx-2 mx-md-1 col-md-2`}
            >
              <a
                href={`https://pinterest.com/pin/create/button/?url=${referralLink}&media=&description=`}
                data-action="share/whatsapp/share"
                target="_blank"
                rel="noreferrer"
                className="abs-full opacityNone overflowHidden"
              >{`https://pinterest.com/pin/create/button/?url=${referralLink}&media=&description=`}</a>
              <img
                src="/icons/pinterest-circular-logo-symbol.svg"
                alt="Pinterest"
                className={styles.socialShareBtns}
              />
            </button>
            <button
              className={`btn btn-custom btn-bg-linkedin width-fit-content mt-3 position-relative col-3 mx-2 mx-md-1 col-md-2`}
            >
              <a
                href={`https://www.linkedin.com/shareArticle?mini=true&url=${referralLink}`}
                data-action="share/whatsapp/share"
                target="_blank"
                rel="noreferrer"
                className="abs-full opacityNone overflowHidden"
              >{`https://www.linkedin.com/shareArticle?mini=true&url=${referralLink}`}</a>
              <img
                src="/icons/linkedin.svg"
                alt="LinkedIn"
                className={styles.socialShareBtns}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
