import React, { useState, useContext } from "react";
import { Link } from "gatsby";
import { StateContext } from "../store/context";
import nameInitials from "../helpers/nameInitials";
import { showModal } from "../store/actions";
import { useGetFundRaisedByChildCampaign } from "../helpers/hooks";
import { currencyFormatterOptions } from "../helpers/constants";
import styles from "./childCampaignList.module.scss";
import CreateFundRaiser from "./createFundRaiser";
import NoDonorsYet from "./noDonorsYet";

function ChildCampaignItem({ childCampaign }) {
  const { data: { fundRaised } = {} } = useGetFundRaisedByChildCampaign(
    childCampaign.node.parentCampaign.slug,
    childCampaign.node.contentful_id
  );

  return (
    <div
      className={`row justify-content-between px-2 ${styles.donorRow} align-items-center`}
      style={{ order: -fundRaised }}
      key={childCampaign.node.contentful_id}
      id={childCampaign.node.contentful_id}
    >
      <div className={`text-white d-flex`}>
        <span
          className={`${styles.initials} text-uppercase text-center text-white`}
        >
          {nameInitials(childCampaign.node.creatorName)}
        </span>
        <span>
          <Link
            to={`/campaign/${childCampaign.node.childSlug}`}
            className="text-decoration-none"
            exact
          >
            <p className={`mb-0 font-18 ${styles.donorName} text-white`}>
              {childCampaign.node.creatorName}
            </p>
          </Link>
          <p className={`mb-0 font-14 text-C4E6FF`}>India</p>
        </span>
      </div>
      <span className={`text-54EDFF font-18`}>
        {new Intl.NumberFormat("en-IN", {
          ...currencyFormatterOptions,
          currency: childCampaign.node.parentCampaign.goalCurrency,
        }).format(fundRaised || 0)}
      </span>
    </div>
  );
}
const ChildCampaignList = ({
  data,
  contentfulId,
  slug,
  campaignTitle,
  campaignGoal,
  campaignGoalCurrency,
}) => {
  const { dispatch } = useContext(StateContext);
  const [donationListScrollable, setDonationListScrollable] = useState(false);
  const enableScroller = () => {
    setDonationListScrollable(true);
  };

  return (
    <section className={`col-12 ${styles.fundRaisingTeamSection}`}>
      <div className={`row px-0 m-0 ${styles.mapDonorRow} align-items-start`}>
        <div className="col-12 col-lg-6 pl-lg-0 text-center text-lg-left mb-5">
          <h3 className="font-36 font-weight-bolder text-white">
            Want to support through your network?
          </h3>
          <p className="font-16 text-FFFFFFB3">
            Just create a supporting fundraiser and share it with your friends
            and family. You will be pleased to see how quick the support will
            start pouring in from well wishers.
          </p>
          <button
            className={`btn button-8BC540 btn-custom font-16 fw-500 letter-spacing-019 width-fit-content text-uppercase mt-3`}
            onClick={() =>
              showModal(dispatch, () => (
                <CreateFundRaiser
                  contentfulId={contentfulId}
                  campaignSlug={slug}
                  campaignTitle={campaignTitle}
                  campaignGoal={campaignGoal}
                  campaignGoalCurrency={campaignGoalCurrency}
                />
              ))
            }
          >
            Start A Supporting Fundraiser
          </button>
        </div>
        <div className="col-12 col-lg-6 pr-lg-0">
          <div className={`${styles.donorListContainer} position-relative`}>
            <div className={`${styles.listBg} abs-full`}></div>
            <div className="mb-4">
              <h3 className="text-white mb-0 font-weight-bolder text-center text-lg-left">
                Fundraising Team
              </h3>
              <small className="d-block text-white text-center text-lg-left">
                *Click to visit the supporting fundraiser
              </small>
            </div>
            <div
              className={`${styles.donorList} ${
                donationListScrollable === true ? styles.listScrollable : ""
              } position-relative row flex-column min-h-100`}
            >
              {data && data.edges.length > 0 ? (
                data.edges.map((x, index) => {
                  return (
                    <ChildCampaignItem
                      childCampaign={x}
                      key={x.node.contentful_id}
                    />
                  );
                })
              ) : (
                <NoDonorsYet
                  colorClass={"text-white"}
                  text={"No Fund Raisers Yet"}
                />
              )}
            </div>

            {data
              ? !donationListScrollable &&
                data.edges.length > 5 && (
                  <div
                    className={`${styles.viewMoreContainer} text-right position-relative`}
                  >
                    <button
                      className={`btn button-8BC540 font-16 fw-500 letter-spacing-019 width-fit-content text-uppercase`}
                      onClick={enableScroller}
                      id="view-more"
                    >
                      View more
                    </button>
                  </div>
                )
              : ""}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChildCampaignList;
